<template>
  <footer class="app-footer" :class="{ 'is-register': isRegistrationFunnel }">
    <div class="app-footer__inner container">
      <template v-if="!isRegistrationFunnel">
        <app-nav-footer v-bind="quickLinks" />
        <app-nav-footer v-bind="whatIsZeronet" />
        <app-nav-footer v-bind="customerCare" />
      </template>
      <app-nav-footer v-bind="terms" />
      <app-nav-footer v-bind="privacy" />
      <template v-if="!isRegistrationFunnel">
        <div class="app-footer__socials">
          <a
            v-for="social in socials"
            :key="social.slug"
            class="app-footer__socials-link"
            :class="`is-${social.slug}`"
            :href="social.url"
            rel="nofollow noopener"
            target="_blank"
            :aria-label="social.label"
          >
            <svg>
              <use :xlink:href="`#${social.slug}`" />
            </svg>
          </a>
        </div>
      </template>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import AppNavFooter from '@/components/global/layout/AppNavFooter.vue';
import { useSocials } from '~/composables/socials';
import { useRegistration } from '~/composables/register';

const { socials } = useSocials();

const { isRegistrationFunnel } = useRegistration();

const quickLinks = {
  title: 'Quick Links',
  items: [
    {
      link: '/login/',
      label: 'My account',
    },
    {
      link: 'https://care.zeronet.co.nz/hc/en-us/categories/5686268289039-Changing-your-plan',
      label: 'Change your plan',
    },
    {
      link: 'https://care.zeronet.co.nz/hc/en-us/articles/5994667034767-What-is-the-difference-between-2-4-GHz-and-5-GHz-Wi-Fi',
      label: 'Wi-Fi options',
    },
    {
      link: '/plans/',
      label: 'Broadband plans',
    },
    {
      link: '/internet-speed-test/',
      label: 'Internet speed test',
    },
    {
      link: '/hyperfibre/',
      label: 'Hyperfibre',
    },
    {
      link: '/promotions/',
      label: 'Promotions',
    },
  ],
};

const whatIsZeronet = {
  title: 'What is Zeronet',
  items: [
    {
      link: '/about/',
      label: 'About us',
    },
    {
      link: '/sustainability/',
      label: 'Sustainability promise',
    },
    {
      link: 'https://care.zeronet.co.nz/hc/en-us/categories/7435988275727-Products-Services',
      label: '*Plan offer summaries',
    },
  ],
};

const customerCare = {
  title: 'Customer Care',
  items: [
    {
      link: 'https://care.zeronet.co.nz/hc/en-us',
      label: 'Support',
    },
    {
      link: 'https://zeronet.networkstatus.co.nz/',
      label: 'Checking for Outages',
    },
    {
      link: '/contact/',
      label: 'Contact us',
    },
    {
      link: 'https://care.zeronet.co.nz/hc/en-us/sections/5686167007631-FAQ',
      label: 'FAQs',
    },
    {
      link: 'https://care.zeronet.co.nz/hc/en-us/categories/5686268289039-Changing-your-plan',
      label: 'Moving house',
    },
    {
      link: 'https://care.zeronet.co.nz/hc/en-us/categories/5765613849999-Router-Setup-Guides',
      label: 'Modem setup',
    },
    {
      link: 'https://care.zeronet.co.nz/hc/en-us/articles/5766263371407-How-do-I-restart-my-router-',
      label: 'How do I restart my router?',
    },
    {
      link: 'https://care.zeronet.co.nz/hc/en-us/articles/5920658161935-How-do-I-find-my-router-s-IP-address-',
      label: "How do I find my router's IP address?",
    },
    {
      link: 'https://care.zeronet.co.nz/hc/en-us/articles/5858657476495-Wi-Fi-Boosters-and-Extenders-A-Complete-Guide',
      label: 'Wi-Fi boosters and Extenders',
    },
    {
      link: 'https://care.zeronet.co.nz/hc/en-us/categories/5765574886543-Troubleshooting-problems',
      label: 'Troubleshooting Ethernet Issues',
    },
  ],
};

const terms = {
  items: [
    {
      link: '/terms/',
      label: 'Terms & Conditions',
    },
  ],
};

const privacy = {
  items: [
    {
      link: '/privacy/',
      label: 'Privacy',
    },
  ],
};
</script>

<style lang="scss" scoped>
.app-footer {
  padding: calc-clamp($mobile, $desktop, 40, 60) 0;
  background-color: var(--primary);
  color: var(--secondary);

  @include touch {
    padding-bottom: rem(100);
  }

  .is-plans &,
  //.is-hyperfibre &,
  .is-about &,
  .is-home &,
  .is-our-plans &,
  .is-contact &,
  .is-page-mobile &,
  .is-5-month-cart-offer &,
  .is-6-month-half-price &,
  .is-index & {
    background-color: var(--secondary);
    color: var(--primary);
  }

  &__inner {
    align-items: start;
    display: grid;
    grid-row-gap: rem(30);

    @include tiny {
      grid-template-columns: 1fr;
      gap: rem(20);
    }

    @include tiny-up {
      justify-content: space-between;
      column-gap: rem(20);
    }

    @include tablet {
      grid-template-columns: repeat(3, max-content);
      grid-row-gap: rem(70);
    }
  }

  &.is-register &__inner {
    @include tablet {
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: rem(70);
    }
  }

  &__socials {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: rem(20);
    grid-auto-rows: rem(20);
    gap: rem(20);

    &-link {
      @include transition;

      @include hover {
        opacity: 0.6;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
